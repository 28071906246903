import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './static/css/main.scss'; // Main styles

const { PUBLIC_URL } = process.env;

// Every route - we lazy load so that each page can be chunked
// NOTE that some of these chunks are very small. We should optimize
// which pages are lazy loaded in the future.
const About = lazy(() => import('./pages/About'));
const Certifications = lazy(() => import('./pages/Certifications'));
const Contact = lazy(() => import('./pages/Contact'));
const Index = lazy(() => import('./pages/Index'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Portfolio = lazy(() => import('./pages/Portfolio'));
const Resume = lazy(() => import('./pages/Resume'));
const Education = lazy(() => import('./pages/Education'));

const App = () => (
  <BrowserRouter basename={PUBLIC_URL}>
    <Suspense>
      <Routes>
        <Route exact path="/" element={<Index/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/certifications" element={<Certifications/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/portfolio" element={<Portfolio/>} />
        <Route path="/education" element={<Education/>} />
        <Route path="/resume" element={<Resume/>} />
        <Route component={NotFound} status={404} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default App;
